<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {

    };
  },
  methods: {

  },
  created() {
    console.log('created');
  },
};
</script>
